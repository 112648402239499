<template>
  <div class="about-us">
    <div class="section section-1">
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="12" lg="6" class="content-wyb">
            <h2 class="title">Who is ESB FX ?</h2>
            <p>
              We are one of the leading online trading providers in Europe. Currently, we are
              honored to be associated with the largest financial sector liquidity (LP) providers in
              the world. This has created favorable conditions for thousands of transactions when
              there are many competing factors such as transaction speed, transaction price, size
              and improvement in technology.
            </p>
            <p>
              The trading products we offer include: forex, commodities, gold, silver, gems, stocks,
              CFDs, futures, digital currencies.
            </p>
            <p>
              With the current development of Blockchain technology, we are confident that we can
              provide our solutions to all customers around the world, including small investors to
              institutional investment funds, funds credit trust. Be a smart investor when choosing
              ESB FX.
            </p>
          </b-col>
          <b-col cols="12" lg="6">
            <b-container class="box-information">
              <b-row>
                <b-col cols="12">
                  <div class="box-info">
                    <div class="item-info">
                      <div class="title-info">Company Name:</div>
                      <div class="detail-info">ENTERPRISE SERVICE MANAGEMENT PTE. LTD</div>
                    </div>
                    <div class="item-info">
                      <div class="title-info">Year of establishment:</div>
                      <div class="detail-info">September 11th, 2021</div>
                    </div>
                    <div class="item-info">
                      <div class="title-info">Company Address:</div>
                      <div class="detail-info">
                        10 Anson Road # 13 - 09 International Plaza Singapore
                      </div>
                    </div>
                    <div class="item-info">
                      <div class="title-info">Company Number:</div>
                      <div class="detail-info">202203414Z</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-2"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.about-us {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    min-height: 70vh;
    padding: 180px 0 50px;
    position: relative;
    .content-wyb {
      color: #fff;
      padding: 0rem 2rem;
      text-align: justify;
      p {
        font-weight: 400;
        letter-spacing: 0.3px;
        line-height: 110%;
      }
    }
    .content-img-wyb-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      max-width: 800px;
      img {
        // max-height: 385px;
        height: auto;
        width: 100%;
        border-radius: 5px;
      }
    }
    .box-information {
      background: #fff;
      border-radius: 25px;
      box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.3);
      position: relative;
      padding: 40px 80px;
      max-width: 1024px;
      width: calc(100% - 15px);
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 6px;
        width: 60%;
        background: #66ac08;
        border-radius: 20px;
        margin: auto;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -3px;
        height: 6px;
        width: 60%;
        background: #66ac08;
        border-radius: 20px;
        margin: auto;
      }
      .item-info {
        margin-bottom: 15px;
        .title-info {
          font-size: clamp(17px, 3vw, 19px);
          font-weight: 600;
          line-height: 120%;
          color: #28a745;
        }
        .detail-info {
          font-size: clamp(15px, 3vw, 17px);
          font-weight: 600;
          line-height: 120%;
        }
      }
    }
    @media screen and (min-width: 1441px) {
      .content-wyb {
        p {
          font-size: 1.125rem;
          line-height: 150%;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 150px 0 40px;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .content-wyb {
        p {
          font-size: 1rem;
          margin-bottom: 15px;
          line-height: 140%;
        }
      }
      .box-information {
        padding: 15px 30px;
        width: auto;
      }
    }

    @media (max-width: 767px) {
      padding: 125px 0 55px;
      min-height: 300px;
      .content-img-wyb-1 {
        display: none;
      }
      .content-wyb {
        text-align: center;
        padding: 20px 15px;
        p {
          margin: auto;
          text-align: center;
        }
      }
    }
  }
  .section-2 {
    padding: 0px 0 10px;
  }
}
</style>
